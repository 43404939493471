.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .top {
    padding: 16px 0;
    background-color: rgba(40, 126, 255, .1);
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    transition: all .3s ease;
  }

  .bottom {
    padding: 10px 0;
    background-color: rgba(40, 126, 255, .1);
    box-shadow: 0 4px 14px rgba(0, 0, 0, .05);
    transition: all .3s ease;
  }

  .content {
    display: flex;
    justify-content: space-between;
    color: var(--content-white)
  }

  .flex {
    display: flex;
    align-items: center;
    column-gap: 24px;

    &:first-child {
      column-gap: 12px;
    }
  }

  .divider {
    width: 2px;
    height: 20px;
    background-color: var(--background-white);
  }

  .text {
    font-weight: 600;
    font-size: 24px;
    line-height: 18px;
    color: var(--content-white);
  }

  .search {
    position: relative;
    width: 420px;

    &Icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    input {
      padding: 8px 50px 8px 16px;
      font-size: 16px;
      line-height: 24px;
      color: var(--content-default);
      border-radius: 44px;
    }

    svg {
      cursor: pointer;
    }
  }
}

.header--white {
  position: static;

  .top {
    background: linear-gradient(269.91deg, #013F7F 0.1%, #0152A4 99.94%);
  }

  .bottom {
    background: linear-gradient(269.91deg, #013F7F 0.1%, #0152A4 99.94%);
  }
}

.header--scroll {
  .top {
    background: linear-gradient(269.91deg, #013F7F 0.1%, #0152A4 99.94%);
  }

  .bottom {
    background: linear-gradient(269.91deg, #013F7F 0.1%, #0152A4 99.94%);
  }
}