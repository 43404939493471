@mixin theme-light {
  // Text colors
  --content-dark: #1B2435;
  --content-black: #000000;
  --content-white: #FFFFFF;
  --content-default: #686868;
  --content-primary: #196DEB;
  --content-secondary: #8787A1;
  --content-danger: #F61E2E;

  // Background colors
  --background-white: #FFFFFF;
  --background-primary: #196DEB;
  --background-secondary: #E9EDF5;
  --background-muted: #E2E2E2;

  // Button colors
  --btn-white: #FFFFFF;
  --btn-white-alt: #8787A1;
  --btn-secondary: #E9EDF5;
  --btn-secondary-alt: #196DEB;
  --btn-primary: #196DEB;
  --btn-primary-alt: #FFFFFF;

  // Border colors
  --border-primary: #E1E1E1;
  --border-secondary: #196DEB;
  --border-danger: #F61E2E;
  --border-white: #FFFFFF;

  // Border radius
  --radius-sm: 5px;
  --radius-md: 10px;
  --radius-lg: 15px;
  --radius-xl: 20px;
  --radius-2xl: 25px;
  --radius-3xl: 30px;

  // Status
  --status-paid-background: #DEFCDD;
  --status-paid-content: #3E963B;
  --status-unpaid-background: #FCD9E0;
  --status-unpaid-content: #E73832;

  // Shadow colors
  --shadow-xs: 0px 4px 38px rgba(0, 0, 0, 0.07);
  --shadow-sm: 0 8px 17px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 8px 17px rgba(0, 0, 0, 0.11);
  --shadow-lg: 0 58px 44px rgba(0, 0, 0, 0.09);
}
