.footer {
  position: relative;
  padding: 64px 0;
  background: linear-gradient(269.91deg, #013F7F 0.1%, #0152A4 99.94%);
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--content-white);
}

.box {
  width: 22%;
}

.title {
  display: block;
  margin-bottom: 30px;
  font-size: 22px;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 16px;
  padding: 0;
  list-style-type: none;

  a {
    color: var(--content-white);
    transition: all .15s ease;

    &:hover {
      opacity: .75;
    }
  }
}

.scrollIcon {
  position: absolute;
  bottom: 100px;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--background-white);
  border-radius: 50%;
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    opacity: .8;
  }
}

.network {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &Link {
    transition: all .15s ease;

    &:hover {
      opacity: .75;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}