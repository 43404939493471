.wrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 6px;
  font-weight: 600;
  border-radius: 5px;
  user-select: none;
  box-shadow: var(--shadow-sm);
  cursor: pointer;
  transition: all .15s ease;

  &--size {
    &-sm {
      padding: 7px 14px;
    }

    &-md {
      padding: 7px 25px;
    }
  }

  &--variant {
    &-white {
      background-color: var(--btn-white);
      color: var(--btn-white-alt);
    }

    &-secondary {
      background-color: var(--btn-secondary);
      color: var(--btn-secondary-alt);
    }

    &-primary {
      background-color: var(--btn-primary);
      color: var(--btn-primary-alt);
    }
  }

  &--block {
    width: 100%;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      transform: scale(1.02);
    }

    &:active {
      transform: scale(0.9995);
    }
  }
}

.title {
  font-size: 16px;
  line-height: 24px;
}

.icon svg {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 500px) {
  .wrapper {
    &--size {
      &-sm {
        padding: 10px 12px;
      }

      &-md {
        padding: 10px 15px;
      }
    }
  }

  .title {
    font-size: 12px;
    line-height: 18px;
  }

  .icon svg {
    width: 20px;
    height: 20px;
  }
}