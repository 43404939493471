.container {
  max-width: 1540px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (max-width: 1600px) {
  .container {
    max-width: 1340px;
  }
}

@media only screen and (max-width: 1400px) {
  .container {
    max-width: 1240px;
  }
}