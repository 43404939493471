.wrapper {
  position: relative;
}

.selected {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: var(--content-white);
  cursor: pointer;
  transition: all .15s ease;

  svg path {
    stroke: var(--content-white);
  }

  &:hover {
    opacity: .5;
  }
}

.languages {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  z-index: 2001;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 0;
  padding: 16px;
  list-style-type: none;
  background: rgba(2, 64, 129, .5);
  border: 1px solid #196DEB;
  box-shadow: 0 5px 10px -5px rgba(255, 255, 255, .2);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all .15s ease;

  &--show {
    visibility: visible;
    opacity: 1;
  }
}

.language {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .5px;
  color: rgba(255, 255, 255, .8);
  cursor: pointer;
  transition: all .15s ease;

  &:hover {
    color: var(--content-primary);
  }
}