@import '../../../node_modules/react-toastify/dist/ReactToastify.css';
@import './base/normalize';
@import './base/typography';
@import './base/themes';

*,
*::before,
*::after {
  box-sizing: border-box;
  //user-select: none;
}

#root {
  height: 100%;
  overflow-x: hidden;
}

:root {
  @include theme-light;
}

body {
  font-family: 'Segoe+UI+Semilight', Roboto, sans-serif;
  background-color: #faf9fe;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input,
textarea,
select,
button {
  outline: none;
  border: none;
}

ul {
  //margin: 0;
  //padding: 0;
  //list-style-type: none;
}

a {
  text-decoration: none;
}

img {
  object-fit: cover;
}

table {
  border-collapse: collapse;
}