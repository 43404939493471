.menu {
  flex-grow: 1;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &ItemLink {
    padding: 10px;
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-white);
    transition: all .15s ease;

    &:hover,
    &--active {
      color: var(--content-primary);
    }
  }
}

.subMenu {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &Content {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px;

    svg path {
      stroke: var(--content-white);
    }
  }

  &:hover &Dropdown {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  &Name {
    font-weight: 600;
    letter-spacing: .5px;
    color: var(--content-white);
    cursor: pointer;
  }

  &Dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2001;
    padding: 16px;
    background-color: var(--background-white);
    border-radius: var(--radius-sm);
    transform: translateY(50px);
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
  }

  &List {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &Link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-dark);
    transition: all .15s ease;

    &:hover {
      color: var(--content-primary);
    }
  }
}