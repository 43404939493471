.wrapper {
  position: relative;
  width: 100%;

  &--has-iconSuffix .input {
    padding-left: 45px;
  }

  &--has-iconPrefix .input {
    padding-right: 45px;
  }
}

.input {
  width: 100%;
  height: 40px;
  padding: 7px 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background-color: var(--background-white);
  color: var(--content-secondary);
  border: 1px solid transparent;
  border-radius: var(--radius-sm);

  &--border {
    border: 1px solid var(--border-primary);
  }

  &--focused {
    border-color: var(--border-primary);
  }

  &--state {
    &-default {
      border-color: var(--border-primary);
    }

    &-success {
      border-color: var(--border-secondary);
    }

    &-error {
      border-color: var(--border-danger);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.iconSuffix {
  position: absolute;
  top: 10px;
  left: 12px;
  color: var(--content-secondary);
}

.iconPrefix {
  position: absolute;
  top: 10px;
  right: 12px;
  color: var(--content-secondary);
}
